@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  width: 100%;

  @media (--viewportMedium) {
    min-height: 450px;
  }

  position: relative;

  &:nth-of-type(odd) {
    background-color: #fafafa;
  }
}

.sectionContent {
  padding: 32px 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 64px 0;
    width: 1500px;
    max-width: 1500px;
  }
}

.sectionContentNoPadding {
  padding: 0 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 0 0;
    width: 1500px;
    max-width: 1500px;
  }
}


.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
