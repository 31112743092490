.articleMain {
  /* 720px + (2 * 32px) == 784 */
  max-width: 1500px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 32px;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}
.centerTitle {
  max-width: 100%;
  text-align:center;
}

.listingCards {
  padding: 0 0 0 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

@media (min-width: 550px) {
  grid-template-columns: repeat(2, 1fr);
}

@media (--viewportMedium) {
  padding: 0 0 0 0;
  grid-template-columns: repeat(2, 1fr);
}

@media (--viewportLarge) {
  grid-template-columns: repeat(4, 1fr);
}

@media (min-width: 1440px) {
  grid-template-columns: repeat(4, 1fr);
}

@media (--viewportXLarge) {
  grid-template-columns: repeat(4, 1fr);
}
}

.listingCard {
  width: 100%;
}
