@import '../../../../styles/customMediaQueries.css';

.categoryBar {
  position: sticky;
  height: 72px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: none;

  @media (--viewportMedium) {
    width: 100%;
    display: flex;
  }
}

.articleMain {
  /* 720px + (2 * 32px) == 784 */
  max-width: 784px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 32px;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.twoButtonDiv {
  display:inline-block;
  text-align:center;
}

.align {
  text-align: left;
  justify-self: start;

@media (--viewportMedium) {
  text-align: center;
  justify-self: center;
}
}

.ctaButton {
  composes: align;
  display: inline-block;
  padding: 8px 20px;
  font-size: 15px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;
  color: white;
  text-decoration: none;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  font-weight: 500;
  margin-top: 24px;
  border-width: 1px;
  border-style: solid;
  margin-right: 24px;

  &:hover {
    text-decoration: none;
    background-color: var(--marketplaceColorDark);
    border-width: 1px;
    border-style: solid;
    border-color: var(--successColor);
  }

 :link {
   text-decoration:none;
 }
}
